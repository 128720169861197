import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CarrouselWrapper,
CarrouselWrapper2,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  VideoWrapper,
  VideosWrapper,
} from "./Phase2.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import Phase2Png from "../../../../res/phase2/portrait.jpg"
import Phase2Png2 from "../../../../res/Photos site/Phase 2/Phase 2 - 1973 by martinez gallery.webp"
import Phase2Png3 from "../../../../res/Photos site/Phase 2/Phase2 (2).jpg"
import Phase2Png4 from "../../../../res/Photos site/Phase 2/Phase 2 (3).webp"
import Phase2Vid1 from "../../../../res/Photos site/Phase 2/phase2.mp4"
import PastPresent1 from "../../../../res/phase2/past-present-1.jpg"
import PastPresent2 from "../../../../res/phase2/past-present-2.jpg"
import PastPresent3 from "../../../../res/phase2/past-present-3.jpg"
const isBrowser = typeof window !== "undefined";
const infos ={
  path: "phase2",
  name: "Phase2",
  description: "Lonny Wod, connu sous le nom de PHASE 2, né le 2 août 1955 dans le Bronx à New York et mort le 13 décembre 2019 à New York, est un des plus célèbres auteurs de graffitis new-yorkais.À la fin de l'année 1972, Phase 2 a utilisé pour la première fois une version précoce de la \" lettre bulle \" ou \" softie \", un style d'écriture qui allait devenir extrêmement influent. Le journaliste Jeff Chang a noté que les oeuvres de Phase 2 de 1973 ont été \"largement reconnues comme définissant le premier genre\".Au fil du temps, le travail de Phase est devenu plus complexe, s'éloignant des simples tags du début des années 1970 pour s'orienter vers \" l'abstraction calligraphique hiéroglyphique \". En 1975, il a ainsi rejoint les United Graffiti Artists nouvellement créés, un collectif d'écrivains professionnels en aérosol qui a commencé à attirer l'attention des médias.",
  pdp: Phase2Png4,
  alt_pdp: "Photo de profil de Phase2.",
  photos: [
    { src: PastPresent1, name: "Phase2" },
    { src: PastPresent2, name: "Phase2" },
    { src: PastPresent3, name: "Phase2" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
};

const Phase2 = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div>
          <h1 className={NameWrapper}>PHASE2</h1>
          <h3 className={NameWrapper} style={{paddingTop: '0'}}>1955 - 2019</h3>
          <p className={DescriptionWrapper}>{infos.description}</p>
        </div>
      </div>
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           
        </div>
      </div>
    </div>
  );
};

export default Phase2;