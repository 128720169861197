// extracted by mini-css-extract-plugin
export var ArtistDescription = "Phase2-module--ArtistDescription--v65Fr";
export var CardWrapper = "Phase2-module--CardWrapper--wW8q7";
export var CarrouselWrapper = "Phase2-module--CarrouselWrapper--Jz8WA";
export var DescriptionWrapper = "Phase2-module--DescriptionWrapper--6bzPz";
export var ExpoWrapper = "Phase2-module--ExpoWrapper--MZMty";
export var NameWrapper = "Phase2-module--NameWrapper--TGyEy";
export var PdpWrapper = "Phase2-module--PdpWrapper--AwtUT";
export var PhotosWrapper = "Phase2-module--PhotosWrapper--3dbCe";
export var ProfilWrapper = "Phase2-module--ProfilWrapper--DNKpl";
export var VideoWrapper = "Phase2-module--VideoWrapper--4RYyJ";
export var VideosWrapper = "Phase2-module--VideosWrapper--hDGhZ";
export var WorkWrapper = "Phase2-module--WorkWrapper--TYO+T";
export var WorksWrapper = "Phase2-module--WorksWrapper--nQmmq";
export var Wrapper = "Phase2-module--Wrapper--WIQeG";